<template>
  <a-spin :spinning="spinning" :tip="exportTip">
    <a-card class="wrap-card" :bordered="false">
      <div class="user">
        <div class="name-info">
          <a-avatar :size="60" :src="user.portrait" />
          <div class="name">
            <div>
              <span class="real-name">
                <OpenData type="userName" :openid="user.realName" />
              </span>
            </div>
            <!-- 的学习档案 -->
            {{ $t("learningArchive.StudyArchives") }}
          </div>
        </div>
        <div class="other-info">
          <div class="other-info-row">
            <!-- 部门： -->
            <span>
              {{ $t("CM_Department") }}：<OpenData
                type="departmentName"
                :openid="user.departmentName"
              />
            </span>
            <!-- 状态： -->
            <span>{{ $t("CM_Status") }}：{{ $t("Pub_Normal") }}</span>
            <!-- 出生日期： -->
            <span
              >{{ $t("login.birthday") }}：{{
                formatBirthday(user.birthday)
              }}</span
            >
          </div>
          <div class="other-info-row">
            <!-- 账号： -->
            <span>{{ $t("login.account") }}：{{ user.account }}</span>
            <!-- 岗位： -->
            <span>{{ $t("Pub_Lab_Post") }}：{{ user.positionName }}</span>
            <!-- 入职时间： -->
            <span
              >{{ $t("cm_entry_time") }}：{{ dateFormat(user.entryDate) }}</span
            >
          </div>
        </div>
      </div>
    </a-card>

    <a-card class="wrap-card" :bordered="false" v-if="data">
      <div class="select-date">
        <a-range-picker
          valueFormat="YYYY-MM-DD"
          style="width: 268px"
          v-model:value="date"
          @change="getData()"
        >
          <template #suffixIcon>
            <CalendarOutlined />
          </template>
        </a-range-picker>
        <!-- 导出pdf -->
        <a-button type="primary" @click="exportData"
          >{{ $t("cm_export") }} pdf</a-button
        >
      </div>
      <div class="statistics">
        <div class="statistics-item">
          <div class="color-1">{{ data.summary.logins }}</div>
          <!-- 登录总次数 -->
          <h4>{{ $t("learningArchive.TotalNumberOfLogins") }}</h4>
        </div>
        <div class="statistics-item">
          <div class="color-2">{{ formatSeconds(data.summary.onlines) }}</div>
          <!-- 在线时长 -->
          <h4>{{ $t("learningArchive.OnlineDuration") }}</h4>
        </div>
        <div class="statistics-item">
          <div class="color-3">{{ formatSeconds(data.summary.learns) }}</div>
          <!-- 学习时长 -->
          <h4>{{ $t("XB_LearningDuration") }}</h4>
        </div>
        <div class="statistics-item">
          <div class="color-4">{{ formatSeconds(data.summary.exams) }}</div>
          <!-- 考试时长 -->
          <h4>{{ $t("LB_Exam_TestTime") }}</h4>
        </div>
        <div class="statistics-item">
          <div class="color-5">{{ data.summary.courses }}</div>
          <!-- 自学课程数 -->
          <h4>{{ $t("learningArchive.NumberOfSelfStudyCourses") }}</h4>
        </div>
        <div class="statistics-item">
          <div class="color-6">
            {{ data.summary.projects + data.summary.packages }}/{{data.summary.newstaffs}}
          </div>
          <!-- 培训项目/新人培训数 -->
          <h4>{{ $t("learningArchive.NumberOfTrainingPrograms") }}/{{ $t("cm_newstaff") }}</h4>
        </div>
      </div>
      <a-divider />
      <div class="wrap">
        <div class="wrap-title">
          <!-- 自学课程（个） -->
          {{ $t("learningArchive.SelfStudyCourse") }}({{ data.summary.courses
          }}{{ $t("cm_ge") }})
        </div>
        <a-table
          :data-source="data.courses"
          rowKey="courseId"
          :bordered="false"
          size="small"
        >
          <!-- 序号 -->
          <a-table-column
            :width="55"
            :title="$t('learningArchive.table.serial')"
            data-index="rowIndex"
          >
            <template #default="{ index }">{{ index + 1 }}</template>
          </a-table-column>
          <!-- 课程名称 -->
          <a-table-column
            :width="200"
            ellipsis
            :title="$t('learningArchive.table.courseName')"
            data-index="courseName"
          >
            <template #default="{ text }">
              <a-tooltip :title="text">{{ text }}</a-tooltip>
            </template>
          </a-table-column>
          <!-- 课程时长(分) -->
          <a-table-column
            :title="$t('learningArchive.table.courseDuration')"
            data-index="coursePeriod"
          >
            <template #default="{ text }">{{ formatSeconds(text) }}</template>
          </a-table-column>
          <!-- 学习时长(分) -->
          <a-table-column
            :title="$t('learningArchive.table.learningDuration')"
            data-index="learnTime"
          >
            <template #default="{ text }">{{ formatSeconds(text) }}</template>
          </a-table-column>
          <!-- 学习进度 -->
          <a-table-column
            :width="80"
            :title="$t('learningArchive.table.studyProgress')"
            data-index="progress"
          >
            <template #default="{ text }">{{ text }}%</template>
          </a-table-column>
          <!-- 开始学习时间 -->
          <a-table-column
            :title="$t('learningArchive.table.startStudyTime')"
            data-index="startTime"
          >
            <template #default="{ text }">{{ dateFormat(text) }}</template>
          </a-table-column>
          <!-- 最后学习时间 -->
          <a-table-column
            :title="$t('learningArchive.table.finalStudyTime')"
            data-index="lastTime"
          >
            <template #default="{ text }">{{ dateFormat(text) }}</template>
          </a-table-column>
        </a-table>
      </div>
      <div class="wrap">
        <div class="wrap-title">
          <!-- 培训项目（个） -->
          {{ $t("MN_TrainingProject_MHome") }}({{ data.projects.length
          }}{{ $t("cm_ge") }})
        </div>
        <Collapse :list="data.projects" />
      </div>
      <div class="wrap">
        <div class="wrap-title">
          <!-- 新人培训（个） -->
          {{ $t("cm_newstaff") }}({{ data.newstaffs.length
          }}{{ $t("cm_ge") }})
        </div>
        <Collapse :list="data.newstaffs" />
      </div>
      <div class="wrap" v-if="isShowPackage">
        <div class="wrap-title">
          <!-- 培训包（个） -->
          {{ $t("cm_package") }}({{ data.packages.length }}{{ $t("cm_ge") }})
        </div>
        <Collapse :list="data.packages" />
      </div>
    </a-card>
  </a-spin>
</template>

<script setup>
import { ref, computed, watch, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { dateFormat, formatSeconds, downloadFile } from "@/utils/tools";

import { getArchive } from "@/api/mine";

import OpenData from "@/components/OpenData.vue";
import Collapse from "./archive/collapse.vue";

const { t: $t } = useI18n();
const { proxy } = getCurrentInstance();
const store = useStore();
const user = computed(() => store.getters.userInfo);
const isShowPackage = ref(false);
const data = ref(null);
const date = ref([]);
const spinning = ref(false);
const exportTip = ref("");

async function getData() {
  spinning.value = true;
  const res = await getArchive(user.value.userId, { date: date.value });
  if (res.ret === 0) {
    res.data.courses = res.data.courses || [];
    res.data.projects = res.data.projects || [];
    res.data.packages = res.data.packages || [];
    res.data.newstaffs = res.data.newstaffs || [];
    data.value = res.data;
  }
  spinning.value = false;
}

watch(
  () => user.value,
  (newVal) => {
    newVal.userId && getData();
  },
  { immediate: true }
);
watch(
  () => store.getters.companyInfo,
  (newVal) => {
    if (newVal.menu) {
      isShowPackage.value = newVal.menu.includes('package');
    }
  },
  { immediate: true }
);

async function exportData() {
  spinning.value = true;
  exportTip.value = $t("cm_downloading");
  await downloadFile(
    "post",
    `study/me/member/archive/${user.value.userId}/export`,
    { date: date.value },
    user.value.realName + $t("learningArchive.StudyArchives") + ".pdf",
    () => {
      proxy.$message.success($t("downloadSuccessfully"));
    }
  );
  spinning.value = false;
  exportTip.value = "";
}

function formatBirthday(time) {
  if (!time) return "--";
  const timeStr = time + "";
  let year = timeStr.substring(0, 4);
  let month = timeStr.substring(4, 6);
  let day = timeStr.substring(6, 8);
  return year + "/" + month + "/" + day;
}
</script>

<style lang="less" scoped>
.wrap-card {
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  margin-bottom: 20px;
}
.real-name {
  color: var(--theme);
  padding-right: 10px;
}
.user {
  .mixinFlex(flex-start, center);
  font-size: 16px;
  color: #333;
  .name-info {
    .mixinFlex(flex-start, center);
    padding-right: 30px;
    border-right: 1px solid #eee;
    .name {
      margin-left: 16px;
      line-height: 30px;
    }
  }
  .other-info {
    flex: 1;
    margin-left: 30px;
    &-row {
      .mixinFlex(flex-start, center);
      span {
        width: 220px;
        .mixinEllipsis(30px);
      }
    }
  }
}
.select-date {
  .mixinFlex(space-between, center);
}
.statistics {
  .mixinFlex(flex-start, center);
  flex-wrap: nowrap;
  margin: 24px 0;
  &-item {
    flex: 1;
    margin-right: 20px;
    padding: 18px 5px;
    text-align: center;
    border-radius: 8px;
    background: #f5f7fb;
    &:last-child {
      margin-right: 0;
      border-right: 0;
    }
    div {
      font-weight: bold;
    }
    h4 {
      margin: 12px 0 0;
      font-size: 12px;
      color: #999;
    }
    .color-1 {
      color: #17b87e;
    }
    .color-2 {
      color: #f90;
    }
    .color-3 {
      color: #377dff;
    }
    .color-4 {
      color: #fc69d8;
    }
    .color-5 {
      color: #17b87e;
    }
    .color-6 {
      color: #377dff;
    }
  }
}
.wrap {
  .wrap-title {
    margin: 24px 0;
    font-size: 20px;
    font-weight: 600;
  }
}
</style>
